import React, { useState } from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide, Flip } from 'react-reveal';
import Wave from 'react-wavify';
import { Link } from "gatsby"; 
import { InPageBot } from "@leadoo/react-bot";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// images
import banner_img from "../images/skanningsprogramvara.svg";
import kodak_software from "../images/attest_overview.png";
import paper_stream from "../images/paper_stream.svg";

import ipad_skanning from "../images/ipad_skanning.svg"
import fakturadata from "../images/fakturadata.png";
import strekcode from "../images/strekcode.svg";
import skanning_img from "../images/skanning.png";

const BOT_CODE = "NUbkN9AF";

function Skanningsprogramvara() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [kontakt_2, set_kontakt_2] = useState(false);
    const [kontakt_3, set_kontakt_3] = useState(false);
    const [kontakt_4, set_kontakt_4] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Skanningsprogramvara | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Programvara för bildfångst b.la. DpuScan, PaperStream och Kodak Capture Pro" />
                <meta name="keywords" content="streckkoder | patchkoder | DpuScan | PaperStream Capture Pro" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Programvara för bildfångst som gör det möjligt att känna igen b.la. streckkoder, patchkoder och text." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/skanningsprogramvara" />
                <link rel="cannonical" href="https://www.optosweden.se/skanningsprogramvara" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Skanningsprogramvara</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Hos oss kan ni välja den programvara för bildfångst som passar just 
                                        era behov. Samtidigt kan ni vara säkra på att ni får hjälp i 
                                        ert val från våra kunniga och erfarna säljare, tekniker och konsulter.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="DpuScan optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>


            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">skanningsprogramvara</span>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        <AnchorLink href="#skanningsprogramvara">
                            <div className="w-full h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={fakturadata} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Skanning och export med en enda knapp gör arbetsflödet enklare och snabbare än någonsin tidigare.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#skanningsprogramvara">
                            <div className="w-full h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={strekcode} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Vi erbjuder ett flertal olika sätt för separering av dokument som streckkoder, patchkoder och text.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#skanningsprogramvara">
                            <div className="w-full h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={skanning_img} alt="ta emot e-fakturor"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        Export av metadata i text, XML, databas och webbtjänst.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-28" id="skanningsprogramvara">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>



            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <Slide left>
                            <div className="w-2/3 p-4 mx-auto">
                                <img className="w-full" src={ipad_skanning} alt="PaperStream Capture Pro" />
                            </div>
                        </Slide>

                        <Slide right>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-gray-900">DpuScan</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    Förutom datainmatning via användardefinierade 
                                    dialogrutor och kvalitetskontroll av skannade buntar, 
                                    är det samtidigt möjligt att göra bildförbättringar, 
                                    känna igen b.la. streckkoder, patchkoder och text. 
                                    Bilder sparas och sorteras automatiskt efter 
                                    information extraherad från dokumenten.
                                    <br/><br/>
                                    DpuScan erbjuder b.la. export av 
                                    metadata i text, XML, databas och webbtjänst.
                                </p>
                                <button className="px-7 py-2 rounded-full mt-10 text-blue-custome border  bg-white shadow-md hover:opacity-90" onClick={() => set_kontakt_2(true)}>Kom i kontakt</button>
                                
                                {kontakt_2 
                                    ? 
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>



            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <Slide left>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-gray-900">PaperStream Capture Pro</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    PaperStream Capture är programvaran för bildfångst 
                                    som är speciellt utformad för Fujitsu-skannrar. 
                                    Med sina alternativ för skanning och export med en 
                                    enda knapp är arbetsflödet enklare och snabbare än någonsin tidigare. 
                                    <br/><br/>
                                    Den erbjuder kraftfull optimering av bildfångst samt 
                                    dataextraktion och organisatoriska möjligheter, den har 
                                    ett intuitivt gränssnitt med sina enkla 
                                    men berikade skanningsprocesser.
                                    PaperStream Capture erbjuder ett flertal olika 
                                    sätt för separering av dokument som t ex 
                                    streckkoder, patchkoder och text.
                                </p>
                                <button className="px-7 py-2 rounded-full mt-10 text-blue-custome border  bg-white shadow-md hover:opacity-90" onClick={() => set_kontakt_3(true)}>Kom i kontakt</button>
                                
                                {kontakt_3 
                                    ? 
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="w-2/3 p-4 mx-auto">
                                <img className="w-full" src={paper_stream} alt="PaperStream Capture Pro" />
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <Slide left>
                            <div className="w-full  p-4 mx-auto">
                                <img className="w-full" src={kodak_software} alt="konvertera skannade information" />
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-gray-900">Kodak Capture Pro Software</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    Kodak Capture Pro hjälper dig att snabbt konverterar 
                                    skannade buntar till användbar information.
                                    Den korrigerar och validerar med automatik för att 
                                    försäkra att du får kvalitetsbilder och korrekt 
                                    information varje gång.
                                    <br/><br/>
                                    Intelligenta jobbval kan styras av specifika 
                                    försättsblad för att automatisera jobbbyten och profiler.
                                    <br/><br/>
                                    Kodak Capture Pro erbjuder ett flertal olika 
                                    sätt för separering av dokument som 
                                    streckkoder, patchkoder och text.
                                </p>
                                <button className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome  shadow-md hover:opacity-90" onClick={() => set_kontakt_4(true)}>Mer info, kontakta oss här!</button>
                                {kontakt_4 
                                    ? 
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>


            <section className="py-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>    
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Skanners</h2>
                                <p className="font-light text-sm text-gray-500">
                                    På OptoSweden har vi över 20 års erfarenhet av skanningslösningar, 
                                    där vi som återförsäljare av flera marknadsledande varumärken har 
                                    kompetensen och möjligheterna att guida dig rätt
                                </p>
                                <Link to="/skanners" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>
                        
                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V9H2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM2 12h12v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z"/>
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Intelligenta automationsplattformar</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Med marknadsledande teknik analyserar vi dina dokument 
                                    oavsett format. Vi läser ut den data du behöver och gör 
                                    den tillgänglig för dig.
                                </p>
                                <Link to="/dokument" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>

                        <Flip top>
                            <div className="px-8 md:px-0">
                                <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>    
                                    </svg>
                                </div>
                                <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Service &amp; Support</h2>
                                <p className="font-light text-sm text-gray-500">
                                    Vi hjälper dig! Vår service-och supportavdelning hjälper er 
                                    med företagets skanner och mjukvara. Vi erbjuder service 
                                    och support men även utbildning på er skanner och mjukvara 
                                    i hela Norden.
                                </p>
                                <Link to="/service" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                    Läs mer
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </Link>
                            </div>
                        </Flip>
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>
        </Layout>
    );
}
export default Skanningsprogramvara;